function capitalizeFirstLetter(string) {
    // null check
    if (!string) return '';
    // capitalize all words
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
    // return string.charAt(0).toUpperCase() + string.slice(1);
}

export { capitalizeFirstLetter };

