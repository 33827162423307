import React, { useState, useEffect } from 'react';
import { createClient } from "@supabase/supabase-js";
import { useParams } from 'react-router-dom';
import NavBar from '../components/NavBar'; // Adjust the path as necessary
import { capitalizeFirstLetter } from '../utils';

const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5idHpub2pqZGt3Z3JoZXp2bm1nIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY3OTkxMjYsImV4cCI6MjA0MjM3NTEyNn0.c2mFkz1Vb0wmP1S_UGoHRW_Rh8ApIzl_YxgqFWd2TrY";
const supabase = createClient("https://nbtznojjdkwgrhezvnmg.supabase.co", key);

function Home() {
  const [movies, setMovies] = useState([]);
  const { imdbId } = useParams();

  useEffect(() => {
    if (!imdbId) {
      getMovies();
    }
  }, [imdbId]);

  async function getMovies() {
    const { data } = await supabase
      .from("movies")
      .select()
      .eq("is_duplicate", false)
      .order("release_date", { ascending: false, nullsFirst: false });
    setMovies(data);
  }

  // write method to get poster image from movie object
  const getPoster = (movie) => {
    if (movie.imdb_id) {
      return `/images/posters/${movie.imdb_id}.png`;
    }
    if (movie.fmd_id) {
      return `/images/posters/${movie.fmd_id}.png`;
    }
    return `/images/poster.png`;
  }

  return (
    <>
      <NavBar />
      <div style={{ textAlign: 'center' }}>
        <h3>Worlds largest collection of free movies</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '20px', padding: '20px' }}>
          {movies.map((movie) => (
            <div key={movie.title || movie.id} style={{ textAlign: 'center' }}>
              <a href={`/movie?title=${movie.title}&imdb_id=${movie.imdb_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <img
                  src={getPoster(movie)}
                  alt={movie.title}
                  style={{ width: '200px', height: '300px', display: 'block', margin: '0 auto 10px', border: '2px solid #000' }} />
                <span style={{ fontSize: '14px', margin: '0 auto 20px' }}>
                  {capitalizeFirstLetter(movie.title)} 
                  {movie.is_verified && 
                  <img src='/images/check.png' alt='Verified' style={{ width: '12px', height: '12px', marginLeft: '5px', verticalAlign: 'middle' }} />}
                  <br />
                  {movie.release_date ? '(' + movie.release_date.split('-')[0] + ')' : ''}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Home;
