import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar'; // Adjust the path as necessary
import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import YouTube from 'react-youtube';
import { capitalizeFirstLetter } from '../utils';

const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5idHpub2pqZGt3Z3JoZXp2bm1nIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY3OTkxMjYsImV4cCI6MjA0MjM3NTEyNn0.c2mFkz1Vb0wmP1S_UGoHRW_Rh8ApIzl_YxgqFWd2TrY";
const supabase = createClient("https://nbtznojjdkwgrhezvnmg.supabase.co", key);

function Movie() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const imdbId = params.get('imdb_id');

  const [movie, setMovie] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var videoId = '';

  useEffect(() => {
    fetchMovieDetails(imdbId);
  }, [imdbId]);

  // fetch from supabase
  async function fetchMovieDetails(imdbId) {
    try {
      const { data, error } = await supabase
        .from('movies')
        .select('*')
        .eq('imdb_id', imdbId)
        .limit(1)
        .single();

      if (error) throw error;

      setMovie(data);
      setLoading(false);
      var videoId = data.url.split('/')[1];
      console.log('videoId:', videoId);

    } catch (err) {
      console.error('Error fetching movie details:', err.message);
      setError(err.message);
    }
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <NavBar />
      <div style={{ display: 'flex', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
        <h1>{capitalizeFirstLetter(movie?.title)} ({new Date(movie.release_date).getFullYear()})</h1>

        {movie.url && (
          <YouTube videoId={movie.url.split('/')[4]} />
        )}

        Source URL:<a href={movie.url}> {movie.url}</a>
        IMDB: <a href={`https://www.imdb.com/title/${movie.imdb_id}`}>Link</a>

      </div>
    </>
  );
}

export default Movie;
